import type { Room } from '@magicschool/supabase/types';
import { getStudentUrl } from '@magicschool/utils/nextjs/url';
import { broadcastRoomStateChange } from 'features/realtime/messages';
import { createStoreSlice } from 'features/store/zustand';
import toast from 'react-hot-toast';
import { type RoomActions, defaultRoom } from './types';

const defaultState = {
  loading: true,
  room: { ...defaultRoom },
  joinInfoModalOpen: false,
  joinInfoUrl: '',
  deleteRoomModalOpen: false,
  deletingRoomText: '',
  confirmRemoveModalOpen: false,
  joinInfoModalStep: 'options' as const,
};

export const buildStudentUrl = (requireLogin: boolean, joinCode: string | null, roomStudentId?: string) => {
  const sessionId = roomStudentId && !requireLogin ? roomStudentId : null;
  const path = requireLogin ? 'login' : 'join';

  const url = new URL(`/s/${path}`, getStudentUrl());
  if (sessionId) url.searchParams.set('sessionId', sessionId);
  if (joinCode) url.searchParams.set('joinCode', joinCode);

  return url.toString();
};

export const createRoomActionsStoreSlice = createStoreSlice(
  'RoomActionsStoreData',
  defaultState,
  ({ set, get, setField }) =>
    ({
      setField,
      loadRoom: (room: Room) => {
        set({ room });
      },
      updateRoom: async (room, onUpdate, intl, broadcastStateChange = true) => {
        const response = await fetch<Room>(`/api/rooms/${room.id}`, {
          method: 'PUT',
          body: JSON.stringify({ state: room.state, name: room.name }),
          responseErrorHandlers: {
            badRequest: () => {
              toast.error(intl.formatMessage({ id: 'room-update.error' }));
              return { shortCircuit: true };
            },
            unknown: () => {
              toast.error(intl.formatMessage({ id: 'room-update.error' }));
              return { shortCircuit: true };
            },
          },
        });
        const updatedRoom = await response.json();
        set({ room: updatedRoom });
        onUpdate(updatedRoom);
        if (broadcastStateChange) {
          broadcastRoomStateChange(room.id, room.state);
        }
      },
      deleteRoom: async (onDelete) => {
        const room = get().room;
        const response = await fetch(`/api/rooms/${room.id}`, { method: 'DELETE' });
        if (!response.ok) return;
        onDelete(room);
        broadcastRoomStateChange(room.id, 'archived');
        set({ deleteRoomModalOpen: false, deletingRoomText: '' });
      },
      openJoinInfoModal: (roomStudentId) => {
        const { room } = get();

        set({
          joinInfoModalOpen: true,
          joinInfoModalStep: roomStudentId ? 'student' : 'options',
          joinInfoUrl: buildStudentUrl(room.require_login, room.join_code, roomStudentId),
        });
      },
    }) as RoomActions,
);
