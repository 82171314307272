import { RoomTeacherRole } from '@magicschool/supabase/types';
import type { MessageModerationData, Room, RoomState, RoomTeacher } from '@magicschool/supabase/types';
import type { RoomStudentsOutputMonitoringResponse, StudentThread } from 'app/api/room_students/[id]/output/route';
import type { RoomStudentResponse } from 'app/api/room_students/[id]/route';
import type { RoomDetailsResponse } from 'app/api/rooms/[id]/details/route';
import type { GetRoomTeachersResponse, RoomTeacherUser } from 'app/api/rooms/[id]/room_teachers/route';
import type { ImageUrlMap } from 'features/assistant/types';
import { broadcastRoomStudentStateChange } from 'features/realtime/messages';
import { defaultRoomWithTeachers } from 'features/room/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import toast from 'react-hot-toast';
import { ImmutableList, ImmutableMap } from 'util/immutable';

export type Student = RoomDetailsResponse['roomStudents'][number];

export type FlaggedStudentThread = StudentThread & MessageModerationData;

export type RoomDetailsStore = {
  loading: boolean;
  loadingThreads: boolean;
  loadingCoTeacher: boolean;
  roomTeacher: RoomTeacher | null;
  room: Room;
  fullRoomToolsMap: ImmutableMap<string, RoomDetailsResponse['fullRoomToolsMap'][number][1]>;
  activeToolsModalOpen: boolean;
  coTeacherDrawerOpen: boolean;
  coTeachers: RoomTeacherUser[];
  pendingCoTeachers: RoomTeacherUser[];
  studentData: Student[];
  threadDrawerOpen: boolean;
  selectedStudent: Student | null;
  studentThreads: StudentThread[] | null;
  joinInfoUrl: string;
  confirmRemoveModalOpen: boolean;
  studentToDelete: Student | null;
  deleteThreadModalOpen: boolean;
  threadToDelete: number | null;
  removeCoTeacherModalOpen: boolean;
  coTeacherToRemove: RoomTeacherUser | null;
  moderationDrawerOpen: boolean;
  flaggedStudentThreads: FlaggedStudentThread[] | null;
  moderationData: ImmutableList<MessageModerationData>;
  unviewedFlaggedMessageCount: number;
  imageUrlMap: ImageUrlMap;
  load: (roomId: string) => Promise<void>;
  setField: SetField<RoomDetailsStore>;
  getStudentThreads: (student: Student) => Promise<void>;
  handleNewRoomStudent: (roomStudentId: string, successMessage: string) => Promise<void>;
  updateStudentRoomState: (roomStudent: Student, state: `${RoomState}`) => Promise<void>;
  openCoTeachersDrawer: () => void;
  loadAndSetCoTeachers: () => Promise<void>;
  startDeleteThread: (threadId: number) => void;
  deleteThread: () => Promise<void>;
  onStudentMessageDeleted: (messageId: number) => void;
  handleNewMessage: ({
    tool_id,
    tool_uuid,
    message,
    roomStudentId,
  }: { tool_id: string; tool_uuid: string; message: string; roomStudentId: string }) => void;
  deleteRoomStudent: () => Promise<void>;
  openModerationDrawer: () => Promise<void>;
  handleFlaggedMessage: (moderation: MessageModerationData) => void;
  isRoomCreatedByCurrentTeacher: () => boolean;
  updateRoomTeacher: (roomTeacherId: string, action: 'remove' | 'approve' | 'deny') => Promise<void>;
};

const defaultState = {
  loading: true,
  loadingThreads: false,
  loadingCoTeacher: false,
  roomTeacher: null,
  room: { ...defaultRoomWithTeachers },
  activeToolsModalOpen: false,
  coTeacherDrawerOpen: false,
  coTeachers: [],
  pendingCoTeachers: [],
  studentData: [],
  threadDrawerOpen: false,
  selectedStudent: null,
  studentThreads: null,
  joinInfoUrl: '',
  fullRoomToolsMap: ImmutableMap<string, RoomDetailsResponse['fullRoomToolsMap'][number][1]>(),
  confirmRemoveModalOpen: false,
  studentToDelete: null,
  deleteThreadModalOpen: false,
  threadToDelete: null,
  removeCoTeacherModalOpen: false,
  coTeacherToRemove: null,
  moderationDrawerOpen: false,
  flaggedStudentThreads: null,
  moderationData: ImmutableList<MessageModerationData>(),
  unviewedFlaggedMessageCount: 0,
  imageUrlMap: ImmutableMap<string, string>(),
};

export const createRoomDetailsStoreSlice = createStoreSlice('RoomDetailsStoreData', defaultState, ({ set, get, setField, getFull }) => ({
  setField,
  load: async (roomId) => {
    set({ ...defaultState, loading: true });
    const response = await fetch(`/api/rooms/${roomId}/details`, {
      responseErrorHandlers: {
        notFound: ({ router }) => {
          router.push('/rooms');
        },
      },
    });
    const { roomTeacher, room, fullRoomToolsMap, roomStudents, moderationData, unviewedFlaggedMessageCount }: RoomDetailsResponse =
      await response.json();
    if (roomTeacher?.role === 'rejected' || roomTeacher?.role === 'pending') {
      set({ roomTeacher, loading: false });
    } else {
      getFull().RoomActionsStoreData.loadRoom(room);

      set({
        roomTeacher,
        room,
        studentData: roomStudents,
        fullRoomToolsMap: ImmutableMap(fullRoomToolsMap),
        moderationData: ImmutableList(moderationData),
        unviewedFlaggedMessageCount,
        loading: false,
      });
      await get().loadAndSetCoTeachers();
    }
  },
  startDeleteThread: (threadId) => {
    set({ deleteThreadModalOpen: true, threadToDelete: threadId });
  },
  deleteThread: async () => {
    const { threadToDelete } = get();
    if (!threadToDelete) return;

    const response = await fetch(`/api/assistant_threads/${threadToDelete}`, { method: 'DELETE' });
    if (response.status === 200) {
      // filter out the thread cause it was deleted
      set((s) => {
        const unviewedMessagesInThread = s.moderationData.filter((m) => m.assistant_thread_id === threadToDelete && !m.viewed).size;
        const updatedModerationData = s.moderationData.filter((m) => m.assistant_thread_id !== threadToDelete);
        return {
          studentThreads: s.studentThreads?.filter((st) => st.assistant_threads.id !== threadToDelete) ?? s.studentThreads,
          deleteThreadModalOpen: false,
          moderationData: updatedModerationData,
          unviewedFlaggedMessageCount: s.unviewedFlaggedMessageCount - unviewedMessagesInThread,
        };
      });
    }
  },
  updateStudentRoomState: async (roomStudent, state) => {
    await fetch(`/api/room_students/${roomStudent.id}`, {
      method: 'PUT',
      body: JSON.stringify({ state }),
    });
    set((s) => {
      const studentData = s.studentData.find((s) => s.id === roomStudent.id);
      if (!studentData) return {};
      studentData.state = state;
      const withoutStudent = s.studentData.filter((s) => s.id !== roomStudent.id);
      withoutStudent.push(studentData);
      return { studentData: withoutStudent };
    });
    const { room } = get();
    broadcastRoomStudentStateChange(room.id, roomStudent.id, state);
  },
  getStudentThreads: async (student) => {
    set({ selectedStudent: student, threadDrawerOpen: true, loadingThreads: true, studentThreads: [] });
    const response = await fetch(`/api/room_students/${student.id}/output`);
    const data: RoomStudentsOutputMonitoringResponse = await response.json();
    set({ studentThreads: data.roomStudentThreadsWithMessages, imageUrlMap: ImmutableMap(data.imageUrlMap), loadingThreads: false });
  },
  openModerationDrawer: async () => {
    set({ moderationDrawerOpen: true, loadingThreads: true, unviewedFlaggedMessageCount: 0 });
    const response = await fetch<any>(`/api/moderation/${get().room.id}`);
    const data = await response.json();
    set({ flaggedStudentThreads: data, loadingThreads: false });
  },
  handleNewMessage: ({ tool_id, tool_uuid, message, roomStudentId }) => {
    set((s) => {
      const studentData = s.studentData.find((s) => s.id === roomStudentId);
      if (!studentData) return {};
      studentData.most_recent_room_student_messages = [{ message, tool_id, tool_uuid }];
      const withoutStudent = s.studentData.filter((s) => s.id !== roomStudentId);
      withoutStudent.push(studentData);
      return { studentData: withoutStudent };
    });
  },
  handleNewRoomStudent: async (roomStudentId, successMessage) => {
    const { studentData } = get();
    if (studentData.find((s) => s.id === roomStudentId)) return;
    const response = await fetch(`/api/room_students/${roomStudentId}`);
    const data: RoomStudentResponse = await response.json();
    set((s) => {
      return {
        studentData: ImmutableList(s.studentData)
          .filter((s) => s.id !== roomStudentId)
          .push(data)
          .toArray(),
      };
    });
    toast.success(`${data.name} ${successMessage}`);
  },
  loadAndSetCoTeachers: async () => {
    set({ loadingCoTeacher: true });
    const { room } = get();
    const response = await fetch(`/api/rooms/${room.id}/room_teachers`);
    const data: GetRoomTeachersResponse = await response.json();

    set({
      joinInfoUrl: `${window.location.origin}/rooms/coTeacher?joinCode=${room.teacher_join_code}`,
      coTeachers: data.roomTeachers.filter((rt) => rt.role === RoomTeacherRole.coteacher),
      pendingCoTeachers: data.roomTeachers.filter((rt) => rt.role === RoomTeacherRole.pending),
      loadingCoTeacher: false,
    });
  },
  openCoTeachersDrawer: () => {
    set({ coTeacherDrawerOpen: true, loadingCoTeacher: true });
    const { loadAndSetCoTeachers } = get();
    loadAndSetCoTeachers();
    set({ loadingCoTeacher: false });
  },
  updateRoomTeacher: async (roomTeacherId: string, action: 'remove' | 'approve' | 'deny') => {
    const { room, loadAndSetCoTeachers } = get();
    set({ loadingCoTeacher: true });
    await fetch(`/api/rooms/${room.id}/room_teachers/${roomTeacherId}`, {
      method: action === 'remove' || action === 'deny' ? 'DELETE' : 'POST',
    });
    await loadAndSetCoTeachers();
    set({
      loadingCoTeacher: false,
    });
  },
  onStudentMessageDeleted: (messageId) => {
    set((state) => {
      const thread = state.studentThreads?.find((thread) =>
        thread.assistant_threads.assistant_thread_messages.find((m) => m.id === messageId),
      );

      if (!thread) return {};

      thread.assistant_threads.assistant_thread_messages = thread.assistant_threads.assistant_thread_messages.filter(
        (m) => m.id !== messageId,
      );

      const withoutThread = state.studentThreads?.filter((t) => t.id !== thread.id) ?? [];
      withoutThread.push(thread);

      const updatedModerationData = state.moderationData.filter((m) => m.assistant_thread_message_id !== messageId);
      const unviewedCount = state.moderationData.filter((m) => m.assistant_thread_message_id === messageId && !m.viewed).size;
      return {
        studentThreads: withoutThread,
        moderationData: updatedModerationData,
        unviewedFlaggedMessageCount: state.unviewedFlaggedMessageCount - unviewedCount,
      };
    });
  },
  deleteRoomStudent: async () => {
    const { studentToDelete } = get();
    if (!studentToDelete) return;

    await fetch(`/api/room_students/${studentToDelete.id}`, { method: 'DELETE' });
    set((s) => ({
      studentData: ImmutableList(s.studentData)
        .filter((s) => s.id !== studentToDelete.id)
        .toArray(),
      confirmRemoveModalOpen: false,
    }));
    broadcastRoomStudentStateChange(studentToDelete.room_id, studentToDelete.id, 'archived');
  },
  handleFlaggedMessage(moderation) {
    set((s) => ({
      moderationData: ImmutableList(s.moderationData).push(moderation),
      unviewedFlaggedMessageCount: s.unviewedFlaggedMessageCount + 1,
    }));
  },
  /**
   * This is confusing.. when the room details loads, it loads with the current user/teacher id. If we're here, we know we have access.
   * We don't return ALL room_teacher rows, just the one that matches the current user id. So we just need to check if the role is 'creator'.
   * If so, we're looking at our own room, if it's administrator, we're looking at a room created by a teacher in our org.
   * @returns boolean - whether the current user is the creator of the room
   */
  isRoomCreatedByCurrentTeacher: () => {
    const { roomTeacher } = get();
    return roomTeacher?.role === 'creator';
  },
}));
